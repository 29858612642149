<template>
    <BackofficeBase :loader_prop="loader_prop">
      <!-- <div id="nav">
        <router-link :to="{path : '/backoffice/point_details', query : {'id' : id}}">פרטים</router-link> | 
        <router-link :to="{path : '/backoffice/backoffice_admin_get_distribution_point_statistics', query : {'id' : id}}">סטטיסטיקה</router-link> | 
        <router-link to="/backoffice/file_uploader">ניהול נק' חלוקה</router-link> 
    </div>
     -->
         <DistributionPointMenu :point_id_prod="point_id_prod" />
        <div>

            <div>
              :מתאריך
            </div>
            <div>
              <input v-model="date_ts" type="date" />
            </div>
            <div>
              :עד תאריך
            </div>
            <div>
              <input v-model="date_ta" type="date" />
            </div>

            <div class="submit_cont">
              <button @click="oclg()" >החל</button>
            <!-- <input type="submit" value="החל" /> -->
          </div>
        </div>
    
      <div v-if=  "view_data.all_packages_by_date">
        <p>{{view_data.all_packages_by_date}} :מספר חבילות שהתקבלו לפי תאריך</p>
      </div>
      <div v-if=  "view_data.all_packages_by_date">
        <p>{{view_data.all_packages_collected_by_date}} :מספר חבילות שנמסרו לפי תאריך</p>
      </div>
    <div>
        <p>{{view_data.all_packages}} :מספר חבילות שהתקבלו</p>
        <p>{{view_data.packages_collected_one_day}} :מספר חבילות שנמסרו ביום האחרון</p>
        <p>{{view_data.packages_collected_week}} :מספר חבילות שנמסרו בשבוע האחרון</p>
        <p>{{view_data.packages_collected_month}} :מספר חבילות שנמסרו בחודש האחרון</p>
        <p>{{view_data.packages_collected}} :מספר חבילות שנמסרו</p>
        <p>{{view_data.packages_waiting}} :מספר חבילות שמחכות</p>
        <!-- <p> :מספר תיבות תקולות</p> -->
    </div>
    <p></p>
    </BackofficeBase>
</template>

<script>
import BackofficeBase from "./BackofficeBase";
import BackendModel from "../../models/BackendModel";
import DistributionPointMenu from "./DistributionPointMenu"


export default {
  name: 'BackofficeAdminGetDistributionPointStatistics',
  components: {
    BackofficeBase,
    DistributionPointMenu
   },
    data() {
      return {
        loader_prop : true,
        view_data : [],
        // id = this.$route.query.id
        date_ts : '',
        date_ta : ''
      }
    },
    async mounted() {
      this.id = this.$route.query.id
      this.point_id_prod = this.$route.query.id
      let date_ts = this.date_ts
      let date_ta = this.date_ta
      let backendModel = new BackendModel()
      let view_data = await backendModel.backendRequest("/Api/service/backoffice/admin_get_distribution_point_statistics", {"id": this.id, "date_ts": date_ts,"date_ta": date_ta})
      this.view_data = view_data.data.view_data
      this.loader_prop = false
      console.log(view_data)
    },
    methods :{
      async  oclg(){
        let backendModel = new BackendModel()
      let view_data =   await backendModel.backendRequest("/Api/service/backoffice/admin_get_distribution_point_statistics", {"id": this.id , "date_ts": this.date_ts,"date_ta": this.date_ta})
        // let params = {
          //     date_ts : this.date_ts,
        //     date_ta : this.date_ta, 
        //     id : this.$route.query.id
        // }
        // console.log(params)
        this.view_data = view_data.data.view_data
      this.loader_prop = false
          console.log(view_data)
        }
    }
}
</script>

<style scoped>
@import "../../assets/style.css"
</style>

